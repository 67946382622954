import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

const SchemaComponent = ({ schemas }) => {
  if (schemas.length === 0) return null;
  const generateSchema = (schemaType, schemaData) => {
    switch (schemaType) {
      case "Organization":
        return {
          "@context": "http://schema.org",
          "@type": "Organization",
          "name": schemaData.name,
          "url": schemaData.url,
          "logo": schemaData.logo,
          "image": schemaData.image,
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": schemaData.contactPoint.telephone,
            "contactType": schemaData.contactPoint.contactType,
            "contactOption": schemaData.contactPoint.contactOption,
            "areaServed": schemaData.contactPoint.areaServed,
            "availableLanguage": schemaData.contactPoint.availableLanguage,
          },
          "address": {
            "@type": "PostalAddress",
            "streetAddress": schemaData.address.streetAddress,
            "addressLocality": schemaData.address.addressLocality,
            "addressRegion": schemaData.address.addressRegion,
            "postalCode": schemaData.address.postalCode,
            "addressCountry": schemaData.address.addressCountry,
          },
          "sameAs": schemaData.sameAs,
        };
      case "Article":
        return {
          "@context": "http://schema.org",
          "@type": "Article",
          "headline": schemaData.headline,
          "author": {
            "@type": "Person",
            "name": schemaData.authorName,
          },
          "datePublished": new Date(schemaData.datePublished).toISOString(),
          "dateModified": new Date(schemaData.dateModified).toISOString(),
          "image": schemaData.image,
          "publisher": {
            "@type": "Organization",
            "name": schemaData.publisherName,
            "logo": {
              "@type": "ImageObject",
              "url": schemaData.publisherLogo,
            },
          },
          "description": schemaData.description,
          "mainEntityOfPage": schemaData.mainEntityOfPage,
        };
      case "BreadcrumbList":
        return {
          "@context": "http://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": schemaData?.map((breadcrumb, index) => ({
            "@type": "ListItem",
            "position": index + 1,
            "name": breadcrumb.name,
            "item": `https://www.almadelic.com${breadcrumb.url}`,
          })),
        };
      case "Person":
        return {
          "@context": "http://schema.org",
          "@type": "Person",
          "name": schemaData.name,
          "url": schemaData.url,
          "address": {
            "@type": "PostalAddress",
            "state": schemaData.address.state,
            "city": schemaData.address.city,
            "country": schemaData.address.country,
          },
          "email": schemaData.email,
          "telephone": schemaData.telephone,
        };
      case "Product":
        return {
          "@context": "http://schema.org",
          "@type": "Product",
          "name": schemaData.name,
          "description": schemaData.description,
          "image": schemaData.image,
          "sku": schemaData.id,
          "brand": {
            "@type": "Organization",
            "name": "Almadelic",
          },
          "offers": {
            "@type": "Offer",
            "url": schemaData.url,
            "priceCurrency": "USD",
            "price": schemaData.price,
            "availability": "https://schema.org/InStock",
            "itemCondition": "https://schema.org/NewCondition",
          },
        };
      default:
        return {};
    }
  };

  return (
    <Helmet>
      {schemas.map((schema, index) => (
        <script key={index} type="application/ld+json">
          {JSON.stringify(generateSchema(schema.type, schema.data))}
        </script>
      ))}
    </Helmet>
  );
};

SchemaComponent.propTypes = {
  schemas: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      data: PropTypes.object.isRequired,
    })
  ).isRequired,
};

export default SchemaComponent;
