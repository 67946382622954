import React from "react";
import { Link } from "react-router-dom";
// Styled Components
import { StyledPageHeaderSectionContainer, StyledPageHeaderTextGrid } from "./index.styles";
import { StyledImage } from "index.styles";
import { StyledButtonContainer } from "index.styles";
// Components
import { Grid } from "@mui/material";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import { TwoColumnSection } from "styles/components/twoColumnSection";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
// Theme
import theme from "assets/theme";
const PageHeader = () => {
  const { isLg } = useMediaQueries();

  const LeftColumn = () => {
    return (
      <Grid item xs={12} lg={6} order={isLg ? 1 : 0}>
        <StyledImage
          height="453"
          width="522"
          src={"https://d3a0rs3velfrz.cloudfront.net/doctorReferralProgram/referralProgram1.webp"}
          alt="Image of a lady smiling at the camera while using her laptop."
        />
      </Grid>
    );
  }

  const RightColumn = () => {
    return (
      <StyledPageHeaderTextGrid item xs={12} lg={5} order={isLg ? 0 : 1}>
        <MKTypography component={"h1"} variant={"h1"} gutterBottom>
          <span
            style={{
              color: theme.palette.accent.main,
              fontSize: "inherit",
              fontWeight: "inherit",
            }}
          >
            Join Our Therapist Program{" "}
          </span>
          for Psychedelic Therapy & Mental Health
        </MKTypography>
        <MKTypography component="p" variant={isLg ? "body1" : "body2"} gutterBottom>
          Are you a mental health professional looking to promote your practice and connect with patients? Join our community of professionals
          dedicated to helping patients continue their journey towards mental well-being.
        </MKTypography>
        <MKTypography component="p" variant={isLg ? "body1" : "body2"} gutterBottom>
          At Almadelic, we believe in the power of collaboration and continuity of care. Our professional community is dedicated to making a
          meaningful difference in the lives of those seeking transformation through KAP.
        </MKTypography>

        <StyledButtonContainer>
          <MKButton
            variant={"contained"}
            color="secondary"
            component={Link}
            aria-label="Join the Almadelic Community Today"
            to="/partners/prospect-survey"
          >
            Join The Almadelic Community Today!
          </MKButton>
        </StyledButtonContainer>
      </StyledPageHeaderTextGrid>
    );
  }

  return (
    <StyledPageHeaderSectionContainer id="therapist-program-header-section" component="section">
      <TwoColumnSection
        leftComponent={<LeftColumn />}
        rightComponent={<RightColumn />}
        columnGap={6}
        rowGap={2}
        id="therapist-program-two-column-section"
      />
    </StyledPageHeaderSectionContainer>
  )
};

export default PageHeader;
