// Major
import React, { Fragment } from "react";
import { Stack } from "@mui/material";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";

// Components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { ImageContainer } from "styles/components/imageContainer";
import HeaderContainer from "styles/components/headerContainer";

// Utils
import useMediaQueries from "utils/mediaQueries.utils";

// Styles
import { StyledSectionContainer } from "styles/components/SectionContainer/index.styles";
import { TwoColumnSection } from "styles/components/twoColumnSection";
import theme from "assets/theme";

const FindTherapist = ({ ...props }) => {
  const { leftColumn, rightColumn, columnReverse } = props;

  const LeftColumn = () => {
    const { title, description, cta } = leftColumn;
    // highlightText
    // if description is an array, then we need to map over the array and return the description as a string
    const descriptionArray = Array.isArray(description) ? description : [description];
    return (
      <Grid item xs={12} sm={12} md={10} lg={6} xl={6} order="2">
        <Stack direction="column" spacing={2} sx={{ margin: "0rem !important" }}>
          <HeaderContainer
            title={title}
            textColor={theme.palette.primary.main}
            component="h2"
            variant="h2"
            // highlightText="Right Therapist"
            highlightColor={theme.palette.accent.main}
          />
          {descriptionArray.map((item, index) => (
            <MKTypography variant="body1" element="body1" key={index}>
              {item}
            </MKTypography>
          ))}

          <MKBox sx={{ alignSelf: "Left" }}>
            {cta ? (
              <MKButton color="secondary" sx={{ padding: "0rem 2rem" }} component={Link} to={cta.link}>
                {cta.text}
              </MKButton>
            ) : null}
          </MKBox>
        </Stack>
      </Grid>
    );
  };

  const RightColumn = () => {
    const { image } = rightColumn;
    return (
      <Grid item xs={12} sm={12} md={10} lg={6} xl={5} order={"1"}>
        <ImageContainer image={image.imageUrl} alt={image.alt} />
      </Grid>
    );
  };

  return (
    <>
      <StyledSectionContainer id="find-therapist-container">
        <TwoColumnSection
          justifyContent="space-betweenhttp://localhost:3000/traditional-therapy"
          leftComponent={<LeftColumn />}
          rightComponent={<RightColumn />}
          columnGap={6}
          rowGap={2}
          direction={columnReverse ? "row" : "row-reverse"}
          id="ketamine-hub-header-section"
        />
      </StyledSectionContainer>
    </>
  );
};

export default FindTherapist;
