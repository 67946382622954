import React from "react";
import { Link } from "react-router-dom";
// Styled Components
import { StyledOurProgramSectionContainer, StyledOurTherapistListText, StyledOurTherapistOuterGrid } from "./index.styles";
import { StyledSectionBackgroundWrapper } from "index.styles";
import { StyledImage } from "index.styles";
import { StyledButtonContainer } from "index.styles";
// Components
import { Grid } from "@mui/material";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import CBDivider from "components/CBDivider";
import { TwoColumnSection } from "styles/components/twoColumnSection";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
// Data
import ourTherapistProgramData from "./ourTherapistProgram.data.json";
// Theme
import theme from "assets/theme";

const OurTherapistProgram = () => {
  const { isLg } = useMediaQueries();


  const LeftColumn = () => {
    return (
      <Grid item xs={12} lg={5}>
        <StyledImage
          height="326"
          width="427"
          src={"https://d3a0rs3velfrz.cloudfront.net/doctorReferralProgram/referralProgram2.webp"}
          alt="Lady talking to her patient explaining our therapist program."
        />
      </Grid>
    )
  }

  const RightColumn = () => {
    return (
      <Grid item xs={12} lg={6} sx={{ alignContent: "center" }}>
        <MKTypography component="h2" variant="h2" sx={{ color: "#fff !important" }} gutterBottom>
          Benefits of the Almadelic
          <span style={{ color: `${theme.palette.accent.main}` }}> {isLg && <br />} Professional Community </span>
        </MKTypography>
        <MKTypography sx={{ color: "#fff" }} variant={isLg ? "body1" : "body2"} gutterBottom>
          At Almadelic, we believe in a collaborative and multidisciplinary approach to mental health. Our community is designed to support mental
          health professionals like you in providing comprehensive care to patients, whether they are seeking traditional therapy or exploring
          Ketamine-Assisted Psychotherapy (KAP) .
        </MKTypography>
        {!isLg && (
          <MKTypography textAlign={isLg && "center"} sx={{ color: "#fff" }} variant={isLg ? "body1" : "body2"}>
            By joining our community, you become part of a supportive network of mental health professionals dedicated to advancing the field of
            psychedelic therapy and providing patients with the best possible care. Whether you choose to list your practice in our directory,
            collaborate with our prescribers, or further your education in psychedelic therapy, we are here to support you every step of the way.
            Together, we can make a meaningful difference in the lives of those seeking healing and transformation through innovative approaches
            to mental health.
          </MKTypography>
        )}
        <StyledButtonContainer>
          <MKButton
            aria-label="Learn More and Join Our Community"
            variant={"contained"}
            color="secondary"
            component={Link}
            to="/partners/prospect-survey"
          >
            Learn More and Join Our Community
          </MKButton>
        </StyledButtonContainer>
      </Grid>
    )
  }
  return (
    <StyledSectionBackgroundWrapper id="our-therapist-program-wrapper">
      <StyledOurProgramSectionContainer id="our-therapist-program-section" component="section">
        <TwoColumnSection
          leftComponent={<LeftColumn />}
          rightComponent={<RightColumn />}
          columnGap={6}
          rowGap={2}
          direction="row"
          id="our-therapist-two-column-section"
        />
        <CBDivider />
        <Grid item xs={12} sx={{m: 5}}>
          <MKTypography textAlign={isLg && "center"} sx={{ color: "#fff" }} variant={isLg ? "body1" : "body2"}>
            By joining our community, you become part of a supportive network of mental health professionals dedicated to advancing the field of
            psychedelic therapy and providing patients with the best possible care. Whether you choose to list your practice in our directory,
            collaborate with our prescribers, or further your education in psychedelic therapy, we are here to support you every step of the way.
            Together, we can make a meaningful difference in the lives of those seeking healing and transformation through innovative approaches to
            mental health.
          </MKTypography>
        </Grid>
        <Grid item>
          <Grid container spacing={6}>
            {ourTherapistProgramData.map(({ title, content, cta }, index) => {
              return (
                <Grid item xs={12} xl={4}>
                  <StyledOurTherapistListText component="h3" variant={"h6"} gutterBottom>
                    {title}
                  </StyledOurTherapistListText>
                  <MKTypography
                    component="p"
                    variant={isLg ? "body1" : "body2"}
                    sx={{ color: `${theme.palette.white.main} !important`, margin: "auto", textAlign: { lg: "center" } }}
                  >
                    {content}
                  </MKTypography>
                  {cta && (
                    <MKBox sx={{ display: "flex", justifyContent: "center" }}>
                      <MKTypography
                        aria-label="Click Here To View Directory"
                        to="/partner-providers"
                        component={Link}
                        sx={{ color: "#75cdc5", textAlign: "center", mt: ".5rem", fontStyle: "italic", cursor: "pointer !important" }}
                      >
                        {cta}
                      </MKTypography>
                    </MKBox>
                  )}
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </StyledOurProgramSectionContainer>
    </StyledSectionBackgroundWrapper>
  );
};

export default OurTherapistProgram;
