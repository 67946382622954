import React from "react";
import { Helmet } from "react-helmet";

// This component is used to dynamically update the page title, meta description, and canonical URL for each page using react helmet
const SEO = ({ title, description, canonicalUrl, prerenderStatusCode, blogPost }) => {
  const fullTitle = `${title}`;
  return (
    <Helmet>
      <title>{fullTitle}</title>
      {description && <meta name="description" content={description} />}
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      {prerenderStatusCode && <meta name="prerender-status-code" content={prerenderStatusCode}></meta>}
      {blogPost && <meta property="og:image" content={blogPost.image} />}
    </Helmet>
  );
};

export default SEO;

// 1. update seo component so we include the social share image
